import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getCompanyUsers, removeUser as remove, updateUser } from '../Api';
import { useAuthStore } from '../Store/useAuthStore';
import jwtDecode from 'jwt-decode';

export type TUser = {
  id?: string;
  name?: string;
  pictures?: {
    profile: {
      url: string;
      key: string;
    };
  };
  phone: string;
  email: string;
  role: string;
};

export const useUsers = () => {
  const queryClient = useQueryClient();
  const { auth } = useAuthStore();

  const QueryKey = ['users'];

  const users = useQuery({
    queryKey: QueryKey,
    queryFn: () => getCompanyUsers(),
    enabled: Boolean(auth),
  });

  // Force update users when authentication changes
  useEffect(() => {
    if (!auth?.access_token) {
      return;
    }

    const payload = jwtDecode(auth.access_token) as {
      org?: string;
      companyId?: string;
    };

    if (payload.companyId || payload.org) {
      queryClient.invalidateQueries({
        queryKey: QueryKey,
      });
    }
  }, [auth?.access_token]);

  const removeUser = async (id: string) => {
    const { error, code } = await remove(id);

    if (error) {
      return Promise.reject(code);
    }

    queryClient.invalidateQueries({
      queryKey: QueryKey,
    });
    return Promise.resolve();
  };

  const updateRole = async (id: string, role: string) => {
    // At the moment the update API only supports updating the role

    try {
      await updateUser(id, role);
      queryClient.invalidateQueries({
        queryKey: QueryKey,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  };

  return {
    users,
    remove: removeUser,
    updateRole,
    isLoading: users.isLoading,
    isError: users.isError,
    isSuccess: users.isSuccess,
  };
};
