import { lightTheme, Text } from '@holdbar-com/pixel';
import {
  MediaItem,
  StockPhotosSearchResponse,
  StockPhotosSearchResult,
} from '@holdbar-com/utils-types';
import { Skeleton, Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import randomBytes from 'randombytes';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { downloadStockPhotoImage, getStockPhotos } from '../../../Api';
import { useTranslate } from '../../../Hooks/useTranslate';
import { trackExperienceFlowStockPhotoSelected } from '../../../tracking/experiences/flow/step-events';
import { trackQuickSetupMediaStepStockPhotoSelected } from '../../../tracking/quick-setup/step-events';
import { Media } from './media';
import { MediaPreview } from './media-preview';
import { StockPhotoSearchResultThumbnail } from './shutterstock-thumbnail';
import { useUploader } from './use-uploader';

type MediaWithStockPhotoIndex = MediaItem & {
  localUrl: string;
  stockPhotoIndex?: number;
};

type StockPhotoSelectorProps = {
  id: string;
  uploadedMedia: MediaItem[];
  placement: 'quick-setup' | 'experience';
  experienceName: Record<string, string | undefined>;
  experienceDescription: Record<string, string | undefined>;
  onChange: (media: MediaItem[]) => void;
};

export const StockPhotoSelector = ({
  id,
  uploadedMedia,
  placement,
  experienceName,
  experienceDescription,
  onChange,
}: StockPhotoSelectorProps) => {
  const { t } = useTranslate('onboarding.dialog.step.media.stockPhotos');
  const flags = useFlags();

  const [selectedPhotoCount, setSelectedPhotoCount] = useState(0);

  const {
    data: stockPhotos,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['stockPhotos', { experienceName, experienceDescription }],

    queryFn: () =>
      getStockPhotos('experience', {
        experienceName,
        experienceDescription,
      }),

    enabled: !!experienceName && flags.featureStockPhotosApiFetch,
    staleTime: Infinity
  });

  const onUploaded = useCallback(
    (newMedia: MediaItem[]) => {
      onChange([...uploadedMedia, ...newMedia]);
    },
    [onChange, uploadedMedia]
  );

  const { onSelect, filestoBeUploaded } = useUploader({
    id,
    fileType: 'cover',
    modelType: 'experience',
    onUploaded,
  });

  const handleSelect = async (content: Blob, stockPhotoIndex: number) => {
    const file = new File([content], randomBytes(16).toString('hex'), {
      type: 'image/jpeg',
    });

    const uploadedFiles = await onSelect([file], stockPhotoIndex);
    const url = uploadedFiles?.[0].type === 'image' ? uploadedFiles[0].url : '';

    const currentCount = selectedPhotoCount + 1;
    setSelectedPhotoCount(currentCount);

    if (placement === 'quick-setup') {
      trackQuickSetupMediaStepStockPhotoSelected(url, currentCount);
    } else if (placement === 'experience') {
      trackExperienceFlowStockPhotoSelected(url, currentCount);
    }
  };

  return (
    <Stack gap={1}>
      <Text variant="medium">{t('title')}</Text>
      <Stack direction="row" gap={2} maxWidth="100%" overflow="auto">
        {isLoading && !error && (
          <Skeleton variant="rounded" width={'100%'} height={130} />
        )}
        <StockPhotosResults
          stockPhotos={stockPhotos}
          filestoBeUploaded={filestoBeUploaded}
          uploadedMedia={uploadedMedia}
          handleSelect={handleSelect}
        />
        {!isLoading && (
          <StockPhotosGeneric
            filestoBeUploaded={filestoBeUploaded}
            uploadedMedia={uploadedMedia}
            handleSelect={handleSelect}
          />
        )}
      </Stack>
      <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
        {t('disclaimer')}
      </Text>
    </Stack>
  );
};

type StockPhotosSectionProps = {
  filestoBeUploaded: { stockPhotoIndex?: number; progress: number }[];
  uploadedMedia: MediaItem[];
  stockPhotos?: StockPhotosSearchResponse | undefined;
  handleSelect: (content: Blob, stockPhotoIndex: number) => void;
};

const StockPhotosResults = ({
  stockPhotos,
  filestoBeUploaded,
  uploadedMedia,
  handleSelect,
}: StockPhotosSectionProps) => {
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);

  useEffect(() => {
    setSelectedIndexes([]);
  }, [filestoBeUploaded]);

  const handleSelectDownloadLink = async (
    result: StockPhotosSearchResult,
    stockPhotoIndex: number
  ) => {
    setSelectedIndexes((prev) => [...prev, stockPhotoIndex]);

    const blob = await downloadStockPhotoImage(
      stockPhotos?.provider ?? 'shutterstock',
      result.id,
      stockPhotos?.searchId
    );

    handleSelect(blob, stockPhotoIndex);
  };

  return (
    <>
      {stockPhotos?.results.map((result, index) => {
        const stockPhoto: MediaItem & { localUrl: string } = {
          type: 'image',
          url: result.thumbnail.url,
          preview: undefined,
          mimeType: 'image/jpeg',
          localUrl: result.thumbnail.url,
        };
        return (
          <Media
            key={index}
            mediaItem={stockPhoto}
            handleClick={() => handleSelectDownloadLink(result, index)}
            progress={
              filestoBeUploaded.find((file) => file.stockPhotoIndex === index)
                ?.progress ?? (selectedIndexes.includes(index) ? 2 : undefined)
            }
            uploaded={Boolean(
              uploadedMedia.find(
                (media) =>
                  (media as MediaWithStockPhotoIndex)?.stockPhotoIndex === index
              ) &&
                !filestoBeUploaded.find(
                  (file) => file.stockPhotoIndex === index
                )
            )}
            disabled={filestoBeUploaded.length > 0}
          >
            <StockPhotoSearchResultThumbnail thumbnail={result.thumbnail} />
          </Media>
        );
      })}
    </>
  );
};

const StockPhotosGeneric = ({
  filestoBeUploaded,
  uploadedMedia,
  handleSelect,
}: StockPhotosSectionProps) => {
  return (
    <>
      {Array(10)
        .fill(undefined)
        .map((_, index) => {
          const stockPhoto: MediaItem & { localUrl: string } = {
            type: 'image',
            url: `/stock-photo/${index}.jpg`,
            preview: undefined,
            mimeType: 'image/jpeg',
            localUrl: `/stock-photo/resized/${index}.jpg`,
          };
          index = index + 100;

          return (
            <Media
              key={index}
              mediaItem={stockPhoto}
              handleClick={async () => {
                const response = await fetch(stockPhoto.url);
                const blob = await response.blob();
                handleSelect(blob, index);
              }}
              progress={
                filestoBeUploaded.find((file) => file.stockPhotoIndex === index)
                  ?.progress
              }
              uploaded={Boolean(
                uploadedMedia.find(
                  (media) =>
                    (media as MediaWithStockPhotoIndex)?.stockPhotoIndex ===
                    index
                )
              )}
              disabled={filestoBeUploaded.length > 0}
            >
              <MediaPreview mediaItem={stockPhoto} />
            </Media>
          );
        })}
    </>
  );
};
