import { ExperienceTag } from '@holdbar-com/utils-types';
import { Stack } from '@mui/material';

import { FlowTypeEnum } from '../../domain/types';
import { IntegrationLinksDropdownExperience } from './IntegrationLinksDropdownExperience';
import { IntegrationLinksDropdownLanguage } from './IntegrationLinksDropdownLanguage';
import { IntegrationLinksDropdownTags } from './IntegrationLinksDropdownTags';

interface IntegrationLinksDropdownsProps {
  languages?: string[];
  flowType: FlowTypeEnum;
  selectedExperience: boolean;
}

export const IntegrationLinksDropdowns = ({
  languages,
  flowType,
  selectedExperience,
}: IntegrationLinksDropdownsProps) => {
  return (
    <Stack gap={2} direction={'row'}>
      <IntegrationLinksDropdownExperience />
      <IntegrationLinksDropdownLanguage languages={languages} />
      {flowType === FlowTypeEnum.Widget && !selectedExperience && (
        <IntegrationLinksDropdownTags />
      )}
    </Stack>
  );
};
