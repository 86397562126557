import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getExperienceWaitlist } from '../Api/waitlist';

export const useExperienceWaitlist = (experienceId: string) => {
  const queryClient = useQueryClient();

  const ExperienceWaitlistQueryKey = ['waitlist', experienceId];

  const waitlist = useQuery({
    queryKey: ExperienceWaitlistQueryKey,

    queryFn: async () => {
      return getExperienceWaitlist(experienceId);
    },

    enabled: !!experienceId,
  });

  return { waitlist };
};
