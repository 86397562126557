import NiceModal from '@ebay/nice-modal-react';
import { Button } from '@holdbar-com/pixel';
import {
  ArrowBackRounded,
  ErrorOutlineRounded,
  ErrorRounded,
} from '@mui/icons-material';
import {
  Box,
  capitalize,
  Card,
  IconButton,
  Skeleton,
  Stack,
  Step,
  StepButton,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import isEmpty from 'lodash.isempty';
import isObject from 'lodash.isobject';
import randomBytes from 'randombytes';
import {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FormProvider,
  useForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { ObjectSchema } from 'yup';

import { IShallowSuccessResponse } from '../../Api';
import { LoadingFullscreen } from '../../Components/Loading/LoadingFullscreen';
import { Spacing } from '../../Components/Spacing/Spacing';
import { useTranslate } from '../../Hooks/useTranslate';
import { OptionsDialog } from '../../Modals/OptionsDialog';
import { SectionContainer } from '../../Sections/SectionContainer/SectionContainer';
import { useErrors } from './useErrors';

export const Page = styled(Box)(({ theme }) => ({
  margin: '40px auto',
  maxWidth: theme.breakpoints.up('xl'),
  width: '100%',
})) as typeof Box;

export const walkObjectRecursively = (
  obj: Record<string, any>,
  handler: (...el: any[]) => boolean
): Record<string, any> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!handler(key, value)) return acc;
    return {
      ...acc,
      [key]:
        isObject(value) && !Array.isArray(value)
          ? walkObjectRecursively(value, handler)
          : value,
    };
  }, {});
};

interface ISyiPage<T> {
  modelType: 'experience' | 'event' | 'voucher' | 'discount';
  steps: { key: string; label: string; formKeys?: string[] }[];
  deleteItem?: UseMutationResult<IShallowSuccessResponse, any, any>;
  updateItem: UseMutationResult<
    IShallowSuccessResponse | void | { id: string },
    any,
    any
  >;
  item: UseQueryResult<T>;
  id: string;
  title: string;
  isCreateFlow?: boolean;
  returnUrl?: string;
  state?: Record<string, any>;
  defaultValues?: UseFormProps['defaultValues'];
  prefiller?: (setValue: UseFormReturn['setValue']) => void;
  schema?: ObjectSchema<any>;
  onSubmit: (
    data: any,
    dirtyFields?: { [k: string]: boolean },
    stayOnPage?: boolean
  ) => Promise<string | void> | undefined;
  isLoading?: boolean;
}

export const createId = () => randomBytes(16).toString('hex');

const processMedia = async (
  media: unknown,
  mediaPromises?: (() => Promise<void>)[]
) => {
  if (mediaPromises) {
    await Promise.all(
      mediaPromises.map(
        (uploadFunc: () => Promise<void>) =>
          new Promise((res, rej) => uploadFunc().then(res).catch(rej))
      )
    ).catch((error) => {
      captureException(error, (scope) => {
        scope.setTransactionName(`Failed to upload media`);
        return scope;
      });
    });
  }

  return Object.entries(media as { [key: string]: any[] }).reduce(
    (acc, [key, images]) => {
      if (!images) {
        return acc;
      }
      return {
        ...acc,
        [key]:
          [...(Array.isArray(images) ? images : [images])]?.map((el: any) => {
            const { localUrl, promise, ...props } = el ?? {};
            return { ...props };
          }) ?? [],
      };
    },
    {}
  );
};

export const SyiPage = <T extends Record<string, any>>({
  children,
  onSubmit,
  title,
  prefiller,
  schema,
  state,
  returnUrl,
  defaultValues,
  modelType,
  isCreateFlow = false,
  steps: _steps,
  id,
  updateItem,
  item,
  isLoading = false,
}: ComponentPropsWithoutRef<'main'> & ISyiPage<T>) => {
  const { t } = useTranslate('utils.generic');

  const [activeStep, setActiveStep] = useState(0);

  const [_returnUrl] = useState(returnUrl);
  const [_state] = useState(state);
  const [_onSubmit] = useState<ISyiPage<any>['onSubmit']>(() => onSubmit);

  const [hasLoadedInitially, setHasLoadedInitially] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldBeCloned, setShouldBeCloned] = useState(false);

  const { validate, errors } = useErrors(schema, id);

  const match: any = useMatch(':id/*');

  const navigate = useNavigate();

  const { getValues, formState, reset, setValue, control, watch, ...methods } =
    useForm<Record<string, any>>({
      defaultValues: {
        ...defaultValues,
        dates: {
          created: new Date().toISOString(),
          modified: new Date().toISOString(),
        },
      },
    });

  const { touchedFields, dirtyFields } = formState;

  useEffect(() => {
    if (isEmpty(touchedFields)) {
      prefiller?.(setValue);
    }
  }, [prefiller]);

  useEffect(() => {
    if (match?.params?.['*']) {
      const pathParts = match?.params?.['*'].split('/');
      const sectionPath = pathParts.pop();
      const foundIndex = steps.findIndex((el) => el.key === sectionPath);
      if (foundIndex > -1) {
        setActiveStep(foundIndex);
      }
    }
  }, [match.params]);

  const initFormValues = (key: string, value: unknown) => {
    if (typeof value === 'object' && value !== null) {
      for (const [subKey, subValue] of Object.entries(value)) {
        initFormValues(`${key}.${subKey}`, subValue);
      }
      return;
    }
    setValue(key, value, { shouldTouch: true });
  };

  useEffect(() => {
    if (item.data && !item.isLoading) {
      for (const [key, value] of Object.entries(item?.data ?? {})) {
        initFormValues(key, value);
      }
      setHasLoadedInitially(true);
    }
  }, [item.data]);

  useEffect(() => {
    if (hasLoadedInitially) {
      if (defaultValues?.parentId === id) {
        setShouldBeCloned(true);
      }
    }
  }, [hasLoadedInitially]);

  useEffect(() => {
    if (_state?.validateOnMount) {
      const { media, mediaPromises, ...data } = getValues();
      validate({ ...data, media }, false);
    }
  }, [hasLoadedInitially]);

  const handleBack = async () => {
    if (!isEmpty(dirtyFields) && !updateItem.isPending) {
      try {
        const choice = await NiceModal.show(OptionsDialog, {
          title: t('title', 'dialogs.unsavedChanges'),
          headline: t('headline', 'dialogs.unsavedChanges'),
          buttons: [
            {
              key: 'cancel',
              label: t('actions.secondary', 'dialogs.unsavedChanges'),
              props: {
                variant: 'outlined',
                color: 'secondary',
              },
            },
            {
              key: 'save',
              label: t('actions.primary', 'dialogs.unsavedChanges'),
              props: {
                variant: 'contained',
              },
            },
          ],
        });

        if (choice === 'save') {
          prepareSubmit();
        }
      } catch (err) {
        return;
      }
    }
    if (_returnUrl) {
      navigate(_returnUrl);
    } else {
      navigate(-1);
    }
  };

  const prepareSubmit = useCallback(
    async (forceUpdate = false, useModal = true, stayOnPage = false) => {
      const { media, mediaPromises, ...data } = getValues();
      setIsSubmitting(true);
      try {
        await validate({ ...data, media }, useModal);
        data.status = 'active';
      } catch (err: any) {
        setIsSubmitting(false);
        if (err !== 'saveDraft') {
          return Promise.reject();
        }
      }

      if (isEmpty(dirtyFields) && !forceUpdate) {
        if (_returnUrl) {
          navigate(_returnUrl);
        } else {
          navigate(-1);
        }
        return;
      }

      const sanitized = walkObjectRecursively(data, (key: string) =>
        Boolean(key && key !== 'undefined')
      );

      if (media) {
        sanitized.media = await processMedia(media, mediaPromises);
      }

      sanitized.dates.modified = new Date().toISOString();

      await _onSubmit(sanitized, dirtyFields, stayOnPage);

      setIsSubmitting(false);
    },
    [dirtyFields, _onSubmit]
  );

  const handleChangeStep = (index: number) => () => {
    setActiveStep(index);
    navigate(steps[index].key, {
      replace: true,
    });
  };

  const handleNext = async () => {
    if (isLastStep) {
      prepareSubmit();
      return;
    }
    setActiveStep((p) => {
      if (p + 1 < steps.length) {
        navigate(steps[p + 1].key, {
          replace: true,
        });
        return p + 1;
      } else return p;
    });
  };
  const handlePrevious = () =>
    setActiveStep((p) => {
      if (p > 0) {
        navigate(steps[p - 1].key, {
          replace: true,
        });
        return p - 1;
      } else return p;
    });

  const steps = useMemo(() => {
    const errorKeys = Object.keys(errors);
    return _steps.map((el) => {
      const _key = el.key
        .split('-')
        .map((m, i) => (i > 0 ? capitalize(m) : m))
        .join('');
      return {
        ...el,
        errors:
          el.formKeys
            ?.flatMap((f) => {
              return errorKeys.filter(
                (k) =>
                  k.startsWith(f) ||
                  k === `${_key}.${f}` ||
                  k === `${_key}.${f}.selectedOptionKey`
              );
            })
            .filter((value, index, self) => self.indexOf(value) === index) ??
          [],
      };
    });
  }, [errors]);

  const isLastStep = activeStep + 1 === steps.length || steps.length === 0;

  const seatsWatch = watch('seats');

  const getGroupValidation = () => {
    if (seatsWatch && seatsWatch.type) {
      if (seatsWatch.type === 'group') {
        if (!seatsWatch.minParticipants || !seatsWatch.maxParticipants)
          return true;
      }
    }
    return false;
  };

  return (
    <>
      <Page maxWidth={1100} position={'relative'}>
        <Spacing height={40} />
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <IconButton onClick={handleBack}>
              <ArrowBackRounded />
            </IconButton>
            <Typography variant={'h2'} ml={2}>
              {title}
            </Typography>
          </Box>
        </Stack>
        <Stack direction={'row'} spacing={5} mt={4}>
          <Box flexGrow={1} data-intercom-target={`syi-content-${modelType}`}>
            <FormProvider
              {...{
                getValues,
                setValue,
                reset,
                formState,
                control,
                watch,
                ...methods,
              }}
            >
              <SectionContainer component={Card} borderRadius={1} p={4}>
                {(item.isLoading || !item.data || !hasLoadedInitially) &&
                !isCreateFlow ? (
                  <Stack spacing={2}>
                    <Skeleton width={60} />
                    <Skeleton width={180} />
                    <Skeleton width={240} />
                    <Skeleton width={164} />
                  </Stack>
                ) : (
                  <Outlet
                    context={{
                      id,
                      state: _state,
                      shouldBeCloned,
                      isCreateFlow,
                      submitForm: (
                        force: boolean,
                        useModal: boolean,
                        stayOnPage?: boolean
                      ) => {
                        prepareSubmit(force, useModal, stayOnPage);
                      },
                    }}
                  />
                )}
              </SectionContainer>
            </FormProvider>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              mt={2}
              color={'white'}
            >
              <Button
                size="medium"
                variant="secondary"
                style={{ ...(steps.length <= 1 && { visibility: 'hidden' }) }}
                disabled={activeStep === 0}
                onClick={handlePrevious}
              >
                {t('back', 'buttons')}
              </Button>

              <Stack direction={'row'} gap={2}>
                {!isCreateFlow && !isLastStep && (
                  <Button
                    size="medium"
                    variant="secondary"
                    disabled={
                      updateItem.isPending ||
                      getGroupValidation() ||
                      isSubmitting
                    }
                    onClick={() => {
                      prepareSubmit();
                    }}
                  >
                    {t('saveAndClose', 'buttons')}
                  </Button>
                )}
                <Button
                  size="medium"
                  variant="primary"
                  disabled={
                    updateItem.isPending || getGroupValidation() || isSubmitting
                  }
                  onClick={handleNext}
                >
                  {isLastStep
                    ? isCreateFlow
                      ? `${t('create', 'buttons')} ${t(
                          modelType,
                          'utils.generic'
                        )}`
                      : t('saveAndClose', 'buttons')
                    : t('next', 'buttons')}
                </Button>
              </Stack>
            </Stack>
          </Box>
          <Box minWidth={'20%'} data-intercom-target={`syi-steps-${modelType}`}>
            {steps.length > 1 && (
              <Box
                sx={{
                  position: 'sticky',
                  top: '16px',
                }}
              >
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step
                      sx={{ cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
                      key={step.label}
                      onClick={handleChangeStep(index)}
                      data-intercom-target={`syi-steps-${modelType}-step-${step.key}`}
                    >
                      <StepButton
                        icon={
                          step.errors.length > 0 ? (
                            index == activeStep ? (
                              <ErrorRounded color={'error'} />
                            ) : (
                              <ErrorOutlineRounded color={'error'} />
                            )
                          ) : null
                        }
                        disableRipple={true}
                      >
                        {step.label}
                        {step.errors.length > 0 && (
                          <Typography fontSize={'1em'} color={'error'}>
                            {step.errors.length}{' '}
                            {step.errors.length > 1
                              ? t('shortcomings')
                              : t('shortcoming')}
                          </Typography>
                        )}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            )}
          </Box>
        </Stack>
      </Page>
      <LoadingFullscreen isLoading={isLoading || updateItem.isPending} />
    </>
  );
};
