import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import * as api from '../Api';

export type FacebookProvider = {
  id: 'facebook';
  params?: {
    pixelId?: string;
    accessToken?: string;
    testId?: string;
    includeUserData: boolean;
  };
};

export type GA4Provider = {
  id: 'ga4';
  params?: {
    measurementId?: string;
    apiSecret?: string;
  };
};

export type GoogleAdsProvider = {
  id: 'googleAds';
  params?: {
    conversionId: string;
    purchaseConversionLabel: string;
  };
};

export type TProvider = (FacebookProvider | GA4Provider | GoogleAdsProvider) & {
  enabled: boolean;
  companyId?: string;
};

export const useMarketing = () => {
  const queryClient = useQueryClient();

  const ProvidersQueryKey = ['marketing', 'providers'];

  const providers = useQuery({
    queryKey: ProvidersQueryKey,

    queryFn: async () => {
      return api.getProviders();
    },

    enabled: true,
  });

  const updateProvider = useMutation({
    mutationFn: (data: TProvider) => api.updateProvider(data),

    onSettled: async (data, error, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: ProvidersQueryKey,
      });
    },
  });

  return {
    providers,
    updateProvider,
  };
};
