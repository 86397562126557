import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { Button, lightTheme } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useExperienceTags } from '../../../../Hooks/useExperienceTags';
import { trackStorefrontTagManagementTagDeleted } from '../../../../tracking/storefront/tags-events';
import { TagDialogWrapper } from './tag-dialog-wrapper';

type DeleteTagDialogProps = {
  tagId: string;
};

export const DeleteTagDialog = NiceModal.create(
  ({ tagId }: DeleteTagDialogProps) => {
    const { t } = useTranslation();
    const [isDeleting, setIsDeleting] = useState(false);

    const {
      tag: { data: tag },
      deleteTag,
    } = useExperienceTags(tagId);

    if (!tag) return null;

    const handleDelete = () => {
      setIsDeleting(true);
      deleteTag
        .mutateAsync(tagId)
        .then(() => {
          toast.success(t('experience.tags.dialog.toast.tagDeleted'));
          trackStorefrontTagManagementTagDeleted();
        })
        .then(handleClose)
        .catch((error) => {
          toast.error(t('utils.errors.generic'));
          console.error(error);
        })
        .finally(() => setIsDeleting(false));
    };

    const modal = useModal();

    const handleClose = () => {
      modal.resolve();
      modal.remove();
    };

    return (
      <TagDialogWrapper
        open={modal.visible}
        handleClose={handleClose}
        title={t('tags.dialog.delete.title')}
        description={t('tags.dialog.delete.description')}
        mobileFullscreen={false}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 2,
            '& > button': {
              flexGrow: 1,
            },
          }}
        >
          <Button
            type="button"
            variant="secondary"
            size="large"
            onClick={handleClose}
          >
            {t('tags.dialog.action.cancel')}
          </Button>
          <Button
            type="button"
            variant="danger"
            size="large"
            onClick={handleDelete}
            disabled={isDeleting}
          >
            {t('tags.dialog.action.delete')}
          </Button>
        </Stack>
      </TagDialogWrapper>
    );
  }
);
