import { Button, lightTheme, LinkButton, Text } from '@holdbar-com/pixel';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { format } from 'date-fns';
import randomBytes from 'randombytes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  Form,
  json,
  useActionData,
  useHref,
  useLinkClickHandler,
  useNavigate,
  useNavigation,
  useRouteLoaderData,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import { adjustGiftCardValue, getGiftCard } from '../../../../Api/Voucher';
import { formatMoney } from '../../../../Components/VariantSelect/VariantSelect';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useFireOnce } from '../../../../Hooks/useFireOnce';
import { t } from '../../../../i18n/config';
import {
  giftCardModifyFlowCompleted,
  giftCardModifyFlowStarted,
} from '../../../../tracking/giftCards/giftCardEvents';
import { DialogWrapper } from '../../../connect/components/dialog-wrapper';
import { LoaderData as GiftCardDetailsLoaderData } from '../gift-card-details-page';

export default function AdjustValueDialog() {
  const { giftCard } = useRouteLoaderData(
    'gift-card-details'
  ) as GiftCardDetailsLoaderData;
  const actionData = useActionData() as ActionData;

  const { t } = useTranslation();

  // Intentionally throwing error so the error element is shown
  if (giftCard.status === 'cancelled')
    throw new Error(t('giftCard.modifyValue.error.isCancelled'));

  const moneyFormatter = formatMoney(t, true);

  const { isSm } = useResponsive();

  const closeHref = useHref(`..`);
  const navigate = useNavigate();
  const fireOnce = useFireOnce();

  useEffect(() => {
    fireOnce(giftCardModifyFlowStarted);
  }, [fireOnce]);

  const handleClose = () => {
    navigate(closeHref);
  };

  if (actionData) {
    return (
      <DialogWrapper
        fullWidth
        maxWidth="sm"
        fullScreen={isSm}
        open={true}
        onClose={handleClose}
        title={t(
          actionData.adjustmentType === 'increase'
            ? 'giftCard.modifyValue.adjustedValue.increasedTitle'
            : 'giftCard.modifyValue.adjustedValue.reducedTitle',
          {
            newAmount: moneyFormatter({
              value: actionData.newAmountLeftCents / 100,
              nativeCurrency: giftCard.currency,
            }),
          }
        )}
      >
        <ValueAdjusted
          amountChangeCents={actionData.amountChangeCents}
          newAmountLeftCents={actionData.newAmountLeftCents}
          adjustmentType={actionData.adjustmentType}
          currency={giftCard.currency}
        />
      </DialogWrapper>
    );
  }

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('giftCard.action.modifyValue')}
    >
      <GiftCardPreview
        currentBalance={moneyFormatter({
          value: giftCard.amountLeftCents / 100,
          nativeCurrency: giftCard.currency,
        })}
        expiryDate={new Date(giftCard.expiresAt)}
        redemptionCode={giftCard.code}
      />
      <AdjustValueForm
        currency={giftCard.currency}
        currentBalanceCents={giftCard.amountLeftCents}
      />
    </DialogWrapper>
  );
}

const GiftCardPreview = ({
  currentBalance,
  redemptionCode,
  expiryDate,
}: {
  currentBalance: string;
  redemptionCode: string;
  expiryDate: Date;
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        padding: 3,
        boxShadow: lightTheme.shadows.large,
        borderRadius: 1,
        maxWidth: 350,
        marginBlock: 4,
        marginInline: 'auto',
        gap: 4,
      }}
    >
      <Stack sx={{ gap: 1 }}>
        <Text variant="medium">{t('vouchers.detailsPage.title')}</Text>
        <Text fontSize="h5">{currentBalance}</Text>
      </Stack>
      <Stack>
        <Text>
          {t('giftCard.details.codeLabel')}:{' '}
          <Text variant="medium">{redemptionCode}</Text>
        </Text>
        <Text>
          {t('giftCard.modifyValue.validUntil', {
            date: format(expiryDate, 'PPP'),
          })}
        </Text>
      </Stack>
    </Stack>
  );
};

const AdjustValueForm = ({
  currency,
  currentBalanceCents,
}: {
  currency: string;
  currentBalanceCents: number;
}) => {
  const { t } = useTranslation();
  const { state } = useNavigation();
  const [adjustmentType, setAdjustmentType] = useState<'reduce' | 'increase'>(
    'reduce'
  );
  const [amountChange, setAmountChange] = useState<number | undefined>();
  const moneyFormatter = formatMoney(t, true);

  const newBalance = amountChange
    ? currentBalanceCents / 100 -
      (adjustmentType === 'reduce' ? amountChange : -amountChange)
    : currentBalanceCents / 100;

  const isNegative = newBalance < 0;

  const isSubmitting = state === 'submitting';

  return (
    (<Stack
      component={Form}
      method="post"
      sx={{ marginTop: 5, gap: 2 }}
      noValidate
    >
      <Stack sx={{ flexDirection: 'row', gap: 1 }}>
        <FormControl fullWidth style={{ maxWidth: 135 }} required>
          <InputLabel shrink id="adjustmentType">
            {t('giftCard.modifyValue.adjustmentType.label')}
          </InputLabel>
          <Select
            labelId="adjustmentType"
            name="adjustmentType"
            value={adjustmentType}
            onChange={(e) => {
              const value = e.target.value;
              if (value === 'reduce' || value === 'increase') {
                setAdjustmentType(value);
              }
            }}
            label="test"
            input={
              <OutlinedInput
                notched
                label={t('giftCard.modifyValue.adjustmentType.label')}
              />
            }
          >
            <MenuItem value="reduce">
              {t('giftCard.modifyValue.adjustmentType.reduce')}
            </MenuItem>
            <MenuItem value="increase">
              {t('giftCard.modifyValue.adjustmentType.increase')}
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          value={amountChange}
          onChange={(e) =>
            setAmountChange((prev) =>
              e.target.value
                ? /^[0-9]+$/.test(e.target.value)
                  ? Number(e.target.value)
                  : prev
                : undefined
            )
          }
          type="number"
          name="amountChange"
          label={
            adjustmentType === 'reduce'
              ? t('giftCard.modifyValue.amountChange.reduceLabel')
              : t('giftCard.modifyValue.amountChange.increaseLabel')
          }
          InputLabelProps={{ shrink: true }}
          inputProps={{
            min: 0,
          }}
          fullWidth
          required
          error={isNegative}
          helperText={
            isNegative && t('giftCard.modifyValue.amountChange.error')
          }
        />
      </Stack>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text fontSize="small">{t('giftCard.modifyValue.newValueLabel')}</Text>
        <Text
          fontSize="small"
          color={isNegative ? lightTheme.palette.error.e300 : undefined}
        >
          {amountChange
            ? moneyFormatter({
                value: newBalance,
                nativeCurrency: currency,
              })
            : '-'}
        </Text>
      </Stack>
      <TextField
        name="reason"
        label={t('giftCard.modifyValue.reason.label')}
        placeholder={t('giftCard.modifyValue.reason.placeholder')}
        InputLabelProps={{ shrink: true }}
        fullWidth
        sx={{ marginTop: 3, marginBottom: 4 }}
      />
      <Button
        type="submit"
        variant="primary"
        size="large"
        disabled={isNegative || !amountChange || isSubmitting}
        loading={isSubmitting}
      >
        {t('giftCard.modifyValue.submitLabel')}
      </Button>
    </Stack>)
  );
};

const ValueAdjusted = ({
  amountChangeCents,
  newAmountLeftCents,
  adjustmentType,
  currency,
}: NonNullable<ActionData> & { currency: string }) => {
  const { t } = useTranslation();

  const moneyFormatter = formatMoney(t, true);

  const closeHref = useHref(`..`);
  const closeLinkClick = useLinkClickHandler(closeHref);

  return (
    <Stack sx={{ gap: 4 }}>
      <Text>
        {t('giftCard.modifyValue.adjustedValue.description', {
          adjustedBy: `${adjustmentType === 'increase' ? '+' : '-'}${moneyFormatter(
            {
              value: amountChangeCents / 100,
              nativeCurrency: currency,
            }
          )}`,
          newValue: moneyFormatter({
            value: newAmountLeftCents / 100,
            nativeCurrency: currency,
          }),
        })}
      </Text>
      <LinkButton
        href={closeHref}
        onClick={closeLinkClick}
        variant="primary"
        size="large"
        fullWidth
        style={{
          flexShrink: 'unset',
        }}
      >
        {t('giftCard.modifyValue.adjustedValue.confirmLabel')}
      </LinkButton>
    </Stack>
  );
};

type ActionData = null | {
  amountChangeCents: number;
  newAmountLeftCents: number;
  adjustmentType: 'reduce' | 'increase';
};

export async function action({ params, request }: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('giftCard.modifyValue.toast.loading'), {
    toastId: loadingToastId,
  });

  const id = params.id;

  if (!id) {
    throw new Response('Invalid id', { status: 404 });
  }

  try {
    const giftCard = await getGiftCard(id);

    if (!giftCard) throw new Error('Gift card not found');

    const formData = await request.formData();

    const adjustmentType = formData.get('adjustmentType');
    const amountChange = Number(formData.get('amountChange'));
    const reason = formData.get('reason');

    if (!adjustmentType) throw new Error('Missing adjustment type');
    if (adjustmentType !== 'increase' && adjustmentType !== 'reduce') {
      throw new Error('Invalid adjustment type');
    }

    if (!amountChange) throw new Error('No change amount');
    if (isNaN(amountChange)) {
      throw new Error('Invalid change amount');
    }

    const amountChangeCents = amountChange * 100;

    if (
      adjustmentType === 'reduce' &&
      amountChange > giftCard.amountLeftCents
    ) {
      throw new Error('Cannot reduce value beyond remaining balance');
    }

    const newGiftCard = await adjustGiftCardValue(id, {
      amountChangeCents:
        adjustmentType === 'reduce' ? amountChangeCents : -amountChangeCents,
      reason: typeof reason === 'string' ? reason : '',
    });

    toast.dismiss(loadingToastId);
    toast.success(t('giftCard.modifyValue.toast.success'), {
      autoClose: 5000,
    });

    // Intentionally not awaiting the event. Optimistic tracking.
    giftCardModifyFlowCompleted({
      hasNote: typeof reason === 'string' && reason.length > 0,
      modifyType: adjustmentType,
      valueAfterModification: newGiftCard.amountLeftCents,
    });

    return json({
      amountChangeCents,
      newAmountLeftCents: newGiftCard.amountLeftCents,
      adjustmentType,
    });
  } catch (error) {
    toast.dismiss(loadingToastId);
    toast.error(t('giftCard.modifyValue.toast.error'), { delay: 500 });
    return null;
  }
}
