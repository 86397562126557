import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getRoles } from '../../Api/User';
import { useLocale } from '../useLocale';

export interface IRole {
  id: string;
  name: string;
  description: string;
  scopes: string[];
  claims: IClaimTemplate[];
}

export interface IClaimTemplate {
  key: string;
  name: string;
  description: string;
  value: string;
}

export const useRoles = () => {
  const { _locale: locale } = useLocale();
  const QueryKey = ['roles', locale];

  const roles = useQuery({
    queryKey: QueryKey,
    queryFn: () => getRoles(locale.code ?? 'en'),
    enabled: true
  });

  return {
    roles: roles,
  };
};
