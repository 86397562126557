import { Text, lightTheme } from '@holdbar-com/pixel';
import { Circle } from '@mui/icons-material';
import { Box, Skeleton, Stack, styled } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type TimelineProps = {
  activities: {
    date: Date;
    title?: string;
    content?: React.ReactNode;
  }[];
  isLoading?: boolean;
};

export const Timeline = ({ activities, isLoading }: TimelineProps) => {
  const { i18n } = useTranslation();

  const groupedActivities = useMemo(() => {
    activities.sort((a, b) => b.date.getTime() - a.date.getTime());

    const reducedActivities = activities.reduce<
      Record<string, TimelineProps['activities']>
    >((acc, activity) => {
      const date = activity.date.toLocaleDateString(i18n.language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(activity);
      return acc;
    }, {});

    return Object.entries(reducedActivities);
  }, [activities, i18n.language]);

  return (
    <Stack gap={3} position="relative">
      {isLoading && <TimelineSkeleton />}
      {groupedActivities.map(([date, activities]) => (
        <Stack key={date} gap={1}>
          <StyledDate fontSize="small">{date}</StyledDate>
          <Stack gap={2}>
            {activities.map(({ title, content }, index) => (
              <Stack key={index} direction="row" gap={3}>
                <StyledCircle />
                <Stack gap={1}>
                  {title && <Text fontSize="small">{title}</Text>}
                  {content}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      ))}
      {groupedActivities.flat().length > 1 && <StyledLine />}
    </Stack>
  );
};

const TimelineSkeleton = () => (
  <Stack gap={2}>
    <Skeleton variant="rounded" width={80} height={12} sx={{ ml: 4.2 }} />
    <Stack gap={2}>
      <Stack direction="row" gap={3}>
        <StyledCircle />
        <Stack gap={1.2}>
          <Skeleton variant="rounded" width={110} height={14} />
          <Skeleton variant="rounded" width={200} height={40} />
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);

const StyledDate = styled(Text)`
  color: ${lightTheme.palette.neutral.n300};
  padding-left: 34px;
  user-select: none;
`;

const StyledCircle = styled(Circle)`
  color: ${lightTheme.palette.neutral.n200};
  font-size: 10px;
  margin-top: 5px;
  z-index: 10;
`;

const StyledLine = styled(Box)`
  position: absolute;
  top: 40px;
  bottom: 8px;
  left: 4px;
  width: 1.5px;
  background: ${lightTheme.palette.neutral.n100};
`;
