import { lightTheme, Text } from '@holdbar-com/pixel';
import { Box, Stack } from '@mui/material';
import { FC } from 'react';

import { useTranslate } from '../../../Hooks/useTranslate';

type Props = {};

export const TicketCheckinSuccess: FC<Props> = () => {
  const { t } = useTranslate('dialogs.booking.checkIn');

  return (
    <Stack justifyContent="space-between" flex={1} gap={3}>
      <Stack gap={2} alignItems="center" marginY={3}>
        <Box position="relative" width={60} height={60}>
          <svg
            width="60"
            height="59"
            viewBox="0 0 60 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="30"
              cy="29.5088"
              r="29.5"
              fill={lightTheme.palette.success.s100}
            />
            <path
              d="M40.8167 22.2158L26.4781 36.5543C25.9341 37.0984 25.0521 37.0984 24.5081 36.5543L18.5278 30.5741"
              stroke={lightTheme.palette.success.s300}
              strokeWidth="4"
              strokeLinecap="round"
            />
          </svg>
        </Box>
        <Text fontSize="large" variant="medium">
          {t('success')}
        </Text>
      </Stack>
    </Stack>
  );
};
