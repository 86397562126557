import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import * as api from '../Api';
import type { CalendarFeedConfig } from '../Api/CalendarFeeds';

export const useCalendarFeed = () => {
  const queryClient = useQueryClient();

  const QueryKey = ['calendarFeed'];

  const feed = useQuery({
    queryKey: QueryKey,

    queryFn: async () => {
      return api.getCalendarFeed();
    },

    enabled: true,
    retry: false,
  });

  const accessKey = useMemo<string>(() => {
    return feed.data?.accessKey ?? '';
  }, [feed]);

  const updateFeed = useMutation({
    mutationFn: (payload: CalendarFeedConfig) =>
      api.updateCalendarFeed(payload),

    onMutate: async (payload) => {
      await queryClient.cancelQueries({
        queryKey: QueryKey,
      });

      const previous = queryClient.getQueryData(QueryKey);

      queryClient.setQueryData<CalendarFeedConfig>(QueryKey, (prev) => {
        return payload;
      });

      return { previous };
    },

    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(QueryKey, context.previous);
      }
    },

    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: QueryKey,
      });
    },
  });

  const deleteFeed = useMutation({
    mutationFn: () => api.deleteCalendarFeed(),

    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: QueryKey,
      });

      const previous = queryClient.getQueryData(QueryKey);

      queryClient.setQueryData<CalendarFeedConfig | {}>(QueryKey, (prev) => {
        return {};
      });

      return { previous };
    },

    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(QueryKey, context.previous);
      }
    },

    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: QueryKey,
      });
    },
  });

  return {
    feed,
    updateFeed,
    deleteFeed,
    accessKey,
  };
};
