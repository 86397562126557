import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  getInvitations,
  IInvitation,
  inviteUser,
  revoke,
  update,
} from '../../Api/Invitation';

export const useInvitations = (status: string = 'pending') => {
  const queryClient = useQueryClient();
  const QueryKey = ['invitations'];

  const invitations = useQuery({
    queryKey: QueryKey,
    queryFn: () => getInvitations(status),
    enabled: true
  });

  const revokeInvitation = async (id: string) => {
    const { error, code } = await revoke(id);

    if (error) {
      return Promise.reject(code);
    }

    queryClient.invalidateQueries({
      queryKey: QueryKey
    });
    return Promise.resolve();
  };

  const invite = async (i: IInvitation) => {
    await inviteUser(i);
    queryClient.invalidateQueries({
      queryKey: QueryKey
    });
  };

  const updateRole = async (id: string, role: string) => {
    try {
      // At the moment the update API only supports updating the role
      await update({
        id,
        claims: {
          role,
          email: '',
          name: '',
        },
        status: 'pending',
        shouldNotify: false,
      });
      queryClient.invalidateQueries({
        queryKey: QueryKey
      });
    } catch (err) {
      return Promise.reject(err);
    }
  };

  return {
    invitations,
    revoke: revokeInvitation,
    invite,
    updateRole,
  };
};
