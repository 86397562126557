import { HoldbarVideo, Image } from '@holdbar-com/utils-types';
import { Box } from '@mui/material';
import { ComponentPropsWithoutRef, useCallback, useState } from 'react';
import { useTus } from 'use-tus';

import * as api from '../Api';
import { useAuthStore } from '../Store/useAuthStore';
import { TFileTypes, TModelTypes } from '../types';

interface IDropzoneProps {
  modelType: TModelTypes;
  id?: string;
  fileType: TFileTypes;
  onPrepared?: (
    media: (
      | Omit<HoldbarVideo, 'videoId' | 'thumbnail'>
      | Omit<Image, 'preview'>
    ) & {
      localUrl: string;
    },
    key: TFileTypes,
    uploadFunc: () => Promise<void>,
    cleanUp: () => void
  ) => void;
}

export const useUploader = ({
  fileType,
  modelType,
  id,
  onPrepared,
}: ComponentPropsWithoutRef<typeof Box> & IDropzoneProps) => {
  const [loading, setLoading] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [tempUrls, setTempUrls] = useState<string[] | null>(null);
  const { setUpload } = useTus({ autoStart: true });
  const { auth } = useAuthStore();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    //setTempUrls(acceptedFiles.map(el => URL.createObjectURL(el)))
    setLoading(`Uploader...`);

    try {
      for (const f of acceptedFiles) {
        try {
          const localUrl = URL.createObjectURL(f);

          if (f.type.includes('video/')) {
            const videoData = {
              playback: {
                hls: '',
                dash: '',
              },
              type: 'video',
              localUrl,
              provider: 'understory',
              mimeType: f.type,
            } as Omit<HoldbarVideo, 'videoId' | 'thumbnail'> & {
              localUrl: string;
            };

            // Upload video
            const uploadFunc = async (): Promise<any> => {
              return new Promise((resolve, reject) => {
                setUpload(f, {
                  endpoint: `${process.env.REACT_APP_API_UPLOAD_URL}/video`,
                  chunkSize: 50 * 1024 * 1024, // Required a minimum chunk size of 5 MB. Here we use 50 MB.
                  uploadSize: f.size,
                  metadata: {
                    filename: f.name,
                    filetype: f.type,
                  },
                  onAfterResponse: (req, res) => {
                    const mediaIdHeader = res.getHeader('stream-media-id');
                    if (mediaIdHeader) {
                      videoData.playback.hls = `https://customer-d4ejfgep0mbcjixt.cloudflarestream.com/${mediaIdHeader}/manifest/video.m3u8`;
                      videoData.playback.dash = `https://customer-d4ejfgep0mbcjixt.cloudflarestream.com/${mediaIdHeader}/manifest/video.mpd`;
                    }
                  },
                  onSuccess: (upload) => {
                    setTempUrls(null);
                    setLoading(null);
                    resolve(videoData);
                  },

                  onBeforeRequest(req, upload) {
                    // We're generating the URL needed to perform the actual upload
                    // on using our API. This means, we need to add authorization to
                    // that call - but not the actual upload.
                    if (
                      !req
                        .getURL()
                        .startsWith('https://upload.')
                    ) {
                      req.setHeader(
                        'Authorization',
                        `${auth?.token_type} ${auth?.access_token}`
                      );
                    }
                  },
                  onProgress: (bytesUploaded, bytesTotal, upload) => {
                    const progress = Math.round(
                      (bytesUploaded / bytesTotal) * 100
                    );

                    if (bytesUploaded < bytesTotal) {
                      setLoading(`Uploader ${progress}%`);
                      setUploadProgress(progress);
                    } else {
                      setLoading(`Behandler...`);
                    }
                  },
                  onError: (error, upload) => {
                    setTempUrls(null);
                    setLoading(null);
                    reject(error);
                  },
                });
              });
            };
            onPrepared?.(videoData, fileType, uploadFunc, () =>
              setTempUrls(null)
            );

            return;
          }

          const { uploadedTo, url } = await api.getSigned(
            fileType,
            modelType,
            f.type,
            id!
          );

          const uploadFunc = async () => {
            return api
              .upload(
                f,
                url,
                (progress) => {
                  if (progress < 100) {
                    setLoading(`Uploader ${progress}%`);
                    setUploadProgress(progress);
                  } else {
                    setLoading(`Behandler...`);
                  }
                },
                f.type
              )
              .finally(() => {
                setTempUrls(null);
                setLoading(null);
              });
          };

          if (url) {
            onPrepared?.(
              {
                url: uploadedTo,
                type: 'image',
                mimeType: f.type,
                localUrl,
              },
              fileType,
              uploadFunc,
              () => setTempUrls(null)
            );
          }

          setTempUrls((p) => (p ? [...p, localUrl] : [localUrl]));
        } catch (err) {}
      }
    } catch (err) {
      console.log('error ', err);
    } finally {
      setLoading(null);
    }
  }, []);

  return {
    onDrop,
    loading,
    uploadProgress,
    tempUrls,
  };
};
